<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col align="center" cols="12"><h1>Bouygues Telecom</h1></v-col>
      <v-col align="center" cols="12"
      ><h2 class="red--text">
        Erreur: Invitation invalide ou expirée
      </h2></v-col
      >
      <v-col align="center" cols="12"
      >Veuillez contacter la personne qui vous a invité
      </v-col
      >
      <v-col align="center" cols="12"
      >Si vous avez un compte vous pouvez rejoindre la page de connexion en
        cliquant sur ce bouton
      </v-col
      >
      <v-btn
        @click="$router.replace('/signin')"
        label="Connexion"
        color="primary"
        class="mt-4"
      >Page de connexion
      </v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
  export default {}
</script>

<style scoped></style>
