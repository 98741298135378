<template>
  <div class="pa-0 pa-0 little_select">
    <v-select
      class="ma-0 pa-0"
      v-model="selected"
      :items="periods"
    >
      <template v-slot:selection="{ item }">
        <span class="text-h6 primary--text"><v-icon color="primary" left>mdi-calendar</v-icon> {{item}}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
  import DateRangePicker from '@/components/hmd/DateRangePicker.vue'

  export default {
    components: {
      DateRangePicker,
    },
    props: {
      value: {
        type: String,
        default: '24 Month',
      },
    },
    data () {
      return {
        periods: ['1 dernier mois', '6 dernier mois', '12 dernier mois', '24 dernier mois'],
        selected: `${this.value.split(' ')[0]} dernier mois`,
      }
    },
    computed: {
      showPersonalized () {
        if (this.selected == 'Personnalisé') {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      value: {
        deep: true,
        handler: function(newVal) {
          this.selected = `${newVal.split(' ')[0]} dernier mois`
        }
      },
      selected (newVal) {
        this.$emit('input', `${this.selected.split(' ')[0]} Month`)
      },
    },
  }
</script>

<style scoped>

.little_select {
  width: 220px!important;
}

</style>
