/* eslint-disable space-before-function-paren */
import { HTTP } from '@/utils/http-common'

export default {
  list ($, company) {
    return new Promise((resolve, reject) => {
      HTTP.get('/projects/manage/bnc/dataset/')
        .then(r => {
          resolve(r)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  get_informations ($, name) {
    return new Promise((resolve, reject) => {
      HTTP.get('/datastore/dataset/manage/' + name + '/')
        .then(r => {
          resolve(r)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  get ($, name, params, headers) {
    const p =
      '?' +
      $.lo.join(
        $.lo.map(params, (o, i) => {
          return i + '=' + (typeof o === 'object' ? JSON.stringify(o) : o)
        }),
        '&',
      )
    return new Promise((resolve, reject) => {
      HTTP.get(
        '/v1/dataset/' + name + '/' + (p.replace('?', '') ? p : ''),
        headers,
      )
        .then(r => {
          resolve(r)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  update ($, datasetName, rowToEdit, fields, show_popin=true) {
    return new Promise((resolve, reject) => {
      const key = `primary_key=${rowToEdit.primary_key}`
      HTTP.post(
        '/v1/dataset/' + datasetName + '/by_id/' + key + '/',
        rowToEdit,
      )
        .then(response => {
          if(show_popin) {
            $.$store.commit('success', 'Information Updated')
          }
          resolve(response)
        })
        .catch(error => {
          if(show_popin) {
            $.$store.commit('error', {
              msg: 'Updating failed',
              color: 'error',
            })
          }
          reject(error)
        })
    })
  },
  create ($, datasetName, row, show_popin = true) {
    return new Promise((resolve, reject) => {
      HTTP.post('/v1/dataset/' + datasetName + '/', row)
        .then(response => {
          if (show_popin) {
            $.$store.commit('success', 'Information created')
          }
          resolve(response)
        })
        .catch(error => {
          let message = ''
          for (const err in error.response.data) {
            message += '- ' + err + ' : ' + error.response.data[err] + '\n'
          }
          if (show_popin) {
            $.$store.commit('error', {
              msg: message,
              color: 'error',
            })
          }

          reject(error)
        })
    })
  },
  deleteRow ($, datasetName, rowToDelete, colums) {
    let keys = {}
    keys = $.lo.join(
      $.lo.map($.lo.filter(colums, { primary_key: true }), (c, i) => {
        return `${c.name}=${rowToDelete[c.name]}`
      }),
      ',',
    )

    return new Promise((resolve, reject) => {
      HTTP.delete('/v1/dataset/' + datasetName + '/by_id/' + keys + '/')
        .then(response => {
          $.$store.commit('success', 'Information removed')
          resolve(response)
        })
        .catch(error => {
          let message = ''
          for (const err in error.response.data) {
            message += '- ' + err + ' : ' + error.response.data[err] + '\n'
          }
          $.$store.commit('error', {
            msg: message,
            color: 'error',
          })
          reject(error)
        })
    })
  },
  exportCsv ($, datasetName) {
    return new Promise((resolve, reject) => {
      HTTP.get('/v1/dataset/' + datasetName + '/export/')
        .then(r => {
          const uri = encodeURI('data:text/csv;charset=utf-8,' + r.data)
          const link = document.createElement('a')
          link.setAttribute('href', uri)
          link.setAttribute(
            'download',
            datasetName.toUpperCase() +
            '_' +
            new Date().toISOString().slice(0, 19) +
            '.csv',
          )
          link.click()
          resolve(link)
        })
        .catch(error => {
          $.$store.commit('error', {
            msg: error.response.data,
            color: 'error',
          })
        })
    })
  },
  truncate (dataset, parent) {
    return new Promise((resolve, reject) => {
      return HTTP.get('/datastore/dataset/manage/' + dataset + '/truncate')
        .then((response) => {
          parent.$store.commit('push', {
            msg: 'Dataset truncated',
            color: 'success',
          })
          return resolve(response)
        }).catch((error) => {
          let errorMessage = ''

          for (let i = 0; i < error.response.data.error.length; i++) {
            if (i > 0) {
              errorMessage += '\n'
            }

            errorMessage += error.response.data.error[i]
          }

          parent.$store.commit('push', {
            msg: errorMessage,
            color: 'error',
          })
          reject(error)
        })
    })
  },
  reimport (parent, project, dataset, isTruncate, file) {
    return new Promise((resolve, reject) => {
      var form = new FormData()
      form.append('project_id', project)
      form.append('truncate', isTruncate)
      form.append('file', file)
      HTTP.post('datastore/dataset/manage/reimport/' + dataset + '/', form)
        .then((response) => {
          parent.$store.commit('push', {
            msg: 'File reimported',
            color: 'success',
          })
          return resolve(response)
        }).catch((error) => {
        let errorMessage = ''

        for (let i = 0; i < error.response.data.error.length; i++) {
          if (i > 0) {
            errorMessage += '\n'
          }

          errorMessage += error.response.data.error[i]
        }

        parent.$store.commit('push', {
          msg: errorMessage,
          color: 'error',
        })
        return reject(error)
      })
    })
  },
}
