/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

import Layout from "@/components/layouts/Layout.vue";

import Dashboard from "@/views/Dashboard.vue";
import UserProfile from "@/views/UserProfile.vue";
import Signin from "@/views/Signin.vue";
import Signup from "@/views/Signup.vue";
import NewPassword from "@/views/NewPassword";

import { requireAuth } from "@/utils/auth";

import Reference from "@/views/Reference.vue";
import NumeroSerie from "@/views/NumeroSerie.vue";
import Alertes from "@/views/Alertes.vue";

import StatPrint from "@/views/StatPrint.vue";
import Users from "@/views/admin/Users";
import Facturation from "@/views/Facturation";

const ActiveLayout = Layout;
const UserRoutes = [];
const AdminRoutes = [
  {
    path: "/overview",
    component: ActiveLayout,
    beforeEnter: requireAuth
  },
  {
    path: "/manage",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Manage",
        component: Users
      }
    ]
  }
];

const SharedRoutes = [
  {
    path: "/overview",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Accueil",
        component: Dashboard
      }
    ]
  },
  {
    path: "/references",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Références",
        component: Reference
      }
    ]
  },
  {
    path: "/numero_serie",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Suivi d'un S/N",
        component: NumeroSerie
      }
    ]
  },
  {
    path: "/signin",
    name: "Sign in",
    component: Signin
  },
  {
    path: "/signup/:key",
    name: "Sign up",
    component: Signup
  },
  {
    path: "/password_reset/:uid/:key",
    name: "Reset password",
    component: NewPassword
  },
  {
    path: "/profile",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Profil",
        component: UserProfile
      }
    ]
  },
  {
    path: "",
    beforeEnter: requireAuth,
    redirect: "/overview"
  },
  {
    path: "/alertes",
    beforeEnter: requireAuth,
    component: ActiveLayout,
    children: [
      {
        path: "",
        name: "Alertes",
        component: Alertes
      }
    ]
  },
  {
    path: "/statistiques",
    beforeEnter: requireAuth,
    component: ActiveLayout,
    children: [
      {
        path: "",
        name: "Statistiques",
        component: Facturation
      }
    ]
  },
  {
    path: "/statistiques/:query/:title/:dateDebut/:dateFin",
    name: "Generation Statistique",
    component: StatPrint
  },
  {
    path: "*",
    redirect: "/overview"
  }
];

export default SharedRoutes.concat(AdminRoutes, UserRoutes);
