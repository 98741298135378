import { HTTP } from "@/utils/http-common";

const HMD_PARTNERS = process.env.HMD_PARTNERS || "sandbox";

export default {
  emailNotReceived(email, $) {
    return new Promise((resolve, reject) => {
      return HTTP.post("/account/registration/resend-confirm-email/", {
        email: email
      })
        .then(response => {
          $.$store.commit("success", {
            msg: "E-mail succesfully sent!",
            color: "success"
          });
          resolve(response);
        })
        .catch(error => {
          $.$store.commit("error", {
            msg: _.join(error.response.data.error, "\n"),
            color: "error"
          });
          reject(error);
        });
    });
  },
  profile($) {
    return new Promise((resolve, reject) => {
      return HTTP.get("/account/settings/user/")
        .then(r => {
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Getting user settings",
            color: "error"
          });
          reject(e);
        });
    });
  },
  updateProfileInfo($, firstName, lastName, username) {
    return new Promise((resolve, reject) => {
      return HTTP.put("/account/settings/user/", {
        first_name: firstName,
        last_name: lastName,
        username: username
      })
        .then(function(response) {
          $.$store.commit("success", "Profile updated");
          resolve(response);
        })
        .catch(function(error) {
          var err = error.response.data;
          for (var prop in err) {
            $.$store.commit("error", {
              msg: prop + " : " + err[prop],
              color: "error"
            });
          }
          reject(error);
        });
    });
  },
  changePassword($, old, new1, new2) {
    return new Promise((resolve, reject) => {
      return HTTP.post("/account/password/change/", {
        new_password1: new1,
        new_password2: new2,
        old_password: old
      })
        .then(function(response) {
          $.$store.commit("success", "Password updated");
          resolve(response);
        })
        .catch(function(error) {
          var err = error.response.data;
          for (var prop in err) {
            $.$store.commit("error", {
              msg: prop + " : " + err[prop],
              color: "error"
            });
          }
          reject(error);
        });
    });
  },
  resetPassword(email, $) {
    return new Promise((resolve, reject) => {
      return HTTP.post("/account/password/reset/", { email: email })
        .then(response => {
          $.$store.commit("success", "E-mail envoyé");
          resolve(response);
        })
        .catch(error => {
          $.$store.commit("error", {
            msg: error.response.data.msg,
            color: "error"
          });
          reject(error);
        });
    });
  },
  sendNewPassword(parent, password1, password2, uid, key) {
    return HTTP.post("/account/password/reset/confirm/", {
      uid: uid,
      token: key,
      new_password1: password1,
      new_password2: password2
    })
      .then(response => {
        parent.$store.commit(
          "success",
          "Mot de passe réinitialisé avec succès, vous allez être rediriger"
        );
      })
      .catch(error => {
        console.log(error);
        parent.$store.commit(
          "error",
          "Une erreur s'est produite durant la réinitialisation de votre mot de passe"
        );
      });
  },
  getInvitation(parent, invitation_key) {
    return new Promise((resolve, reject) => {
      HTTP.post("/invitations/get_invitation/", {
        key: invitation_key
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          parent.$store.commit("error", {
            text: error,
            color: "error"
          });
        });
    });
  }
};
