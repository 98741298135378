<template>
  <v-card color="grey lighten-4">
    <!-- Create dialog component -->
    <v-dialog v-if="create_dialog" v-model="create_dialog" width="450px">
      <v-card>
        <v-card-title>
          Create a new group
        </v-card-title>
        <v-card>
          <v-text-field
            class="mx-5"
            single-line
            label="Nom du groupe"
            hide-details
            v-model="groupForm.name"
          ></v-text-field>
        </v-card>
        <v-card-actions class="d-flex justify-end pa-5">
          <v-btn color="error" @click="cancelCreateGroup">Cancel</v-btn>
          <v-btn color="success" @click="createGroup" :loading="create_loading"
            >Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit Dialog component -->
    <v-dialog v-if="edit_dialog" v-model="edit_dialog" width="450px">
      <v-card>
        <v-card-title>Edition du groupe</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom du groupe"
            v-model="groups[getGroupIndex(selectedGroup)].name"
            :disabled="true"
          />
          <h3>Membres</h3>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedUser"
                :items="users"
                label="Ajouter un membre au groupe"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.email }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.email }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-btn @click="addUser">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Ajouter un membre
              </v-btn>
            </v-col>
          </v-row>
          <v-list dense class="mt-3">
            <v-list-item
              class="my-5"
              v-for="member in groups[getGroupIndex(selectedGroup)].members"
              :key="member.id"
            >
              <v-row width="100%">
                <v-col cols="10">
                  <v-row class="my-0 py-0">
                    <v-col cols="12" class="my-0 py-0">
                      <v-icon left>mdi-email</v-icon>
                      {{ member.email }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    rounded
                    outlined
                    color="error"
                    @click="removeUser(member.pk)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pa-5">
          <v-btn color="primary" @click="cancelUpdateGroup">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Main component -->
    <v-card-title>
      Administration des groupes
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="ml-3" cols="4">
          <v-text-field
            v-model="search"
            label="Rechercher"
            single-line
            hide-details
            append-icon="mdi-home-search"
          />
        </v-col>
      </v-row>
      <v-data-table
        :items="groups"
        :headers="columns"
        :search="search"
        hide-default-footer
        class="elevation-1 mt-2"
      >
        <template v-slot:item.permissions.codename="{ item }">
          <v-chip
            class="mt-1"
            v-for="permission in item.permissions"
            :key="permission.id"
          >
            {{ permission.codename }}
          </v-chip>
        </template>
        <template v-slot:item.object_permissions.codename="{ item }">
          <v-chip
            class="mt-1"
            v-for="permission in item.object_permissions"
            :key="permission.id"
          >
            {{ permission.codename }} on
            {{ get_object_name(permission.codename) }}
            {{ permission.object_name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="my-1"
            rounded
            color="primary"
            @click="openUpdateDialog(item.id)"
          >
            <v-icon fab class="mr-2">mdi-home-edit</v-icon>
            Editer
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import CompanyGroupService from "@/services/CompanyGroupService";
import SettingsService from "@/services/SettingsService";
import CompanyService from "@/services/CompanyService";
import Constant from "@/constant";

export default {
  props: {
    authorized_groups: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      loading: true,
      create_dialog: false,
      groups: [],
      columns: [
        {
          text: "Nom du groupe",
          value: "name",
          align: "left"
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false
        }
      ],
      search: "",
      selectedGroup: null,
      groupForm: {
        name: ""
      },
      companyId: this.$store.getters.user.company_id,
      create_loading: false,
      edit_dialog: false,
      edit_loading: false,
      group_update_form_old_name: "",
      users: null,
      selectedUser: null
    };
  },
  mounted() {
    CompanyGroupService.list_group_company(this).then(result => {
      if (this.authorized_groups.length > 0) {
        this.groups = result.filter(g => {
          return this.authorized_groups.includes(g.name);
        });
      } else {
        this.groups = result;
      }
      this.loading = false;
    });

    return CompanyService.get(this, Constant.company).then(r => {
      r.users = r.users.map(u => {
        u.company = Constant.company;
        return u;
      });
      if (this.authorized_groups.length > 0) {
        r.users = r.users.filter(u => {
          if (u.groups.length === 0) {
            return true;
          } else {
            for (let g of u.groups) {
              if (this.authorized_groups.includes(g)) return u;
            }
          }
        });
      }
      this.users = r.users;
    });
  },
  methods: {
    get_object_name(codename) {
      let obj = codename.split("_");
      obj = obj[obj.length - 1];
      obj = obj.charAt(0).toUpperCase() + obj.slice(1);
      return obj;
    },
    get_permission(codename) {
      let obj = codename.split("_");
      obj.splice(obj.length - 1, 1);
      obj = obj.join("_");
      return obj;
    },
    get_type_permission(codename) {
      let obj = codename.split("_");
      obj = obj[obj.length - 1];
      return obj;
    },
    getGroupIndex(idGroup) {
      return this.groups.findIndex(g => g.id === idGroup);
    },
    getMemberIndex(idGroup, idUser) {
      let group = this.groups[this.getGroupIndex(idGroup)];
      return group.members.findIndex(m => m.pk === idUser);
    },
    getIndexPermission(idGroup, idPermission) {
      let group = this.groups[this.getGroupIndex(idGroup)];
      return group.permissions.findIndex(p => p.id === idPermission);
    },
    getIndexObjectPermission(idGroup, idPermission) {
      let group = this.groups[this.getGroupIndex(idGroup)];
      return group.object_permissions.findIndex(p => p.id === idPermission);
    },
    confirmDeleteGroup(idGroup) {
      this.selectedGroup = idGroup;
      this.$store.commit("confirm", {
        text:
          "Do you really want to delete this groups ? This action cannot be undone, be careful.",
        confirmBtnTxt: "DELETE",
        onConfirm: this.deleteGroup,
        onCancel: this.cancelDeleteGroup
      });
    },
    deleteGroup() {
      CompanyGroupService.delete_group_company(this, this.selectedGroup).then(
        () => {
          let index = this.getGroupIndex(this.selectedGroup);
          this.groups.splice(index, 1);
        }
      );
    },
    cancelDeleteGroup() {
      this.selectedGroup = null;
    },
    createGroup() {
      this.create_loading = true;
      CompanyGroupService.create_group_company(
        this,
        this.groupForm.name,
        this.companyId
      ).then(result => {
        this.groups.push(result);
        this.cancelCreateGroup();
      });
    },
    cancelCreateGroup() {
      this.groupForm.name = "";
      this.create_dialog = false;
      this.create_loading = false;
    },
    openUpdateDialog(groupId) {
      this.selectedGroup = groupId;
      this.edit_dialog = true;
      let index = this.getGroupIndex(groupId);
      this.group_update_form_old_name = this.groups[index].name;
    },
    updateGroup() {
      this.edit_loading = true;
      let index = this.getGroupIndex(this.selectedGroup);
      if (this.group_update_form_old_name != this.groups[index].name) {
        CompanyGroupService.update_group_company(this, this.selectedGroup, {
          name: this.groups[index].name
        }).then(result => {
          this.group_update_form_old_name = result.name;
          this.cancelUpdateGroup();
        });
      } else {
        this.cancelUpdateGroup();
      }
    },
    cancelUpdateGroup() {
      let index = this.getGroupIndex(this.selectedGroup);
      this.edit_dialog = false;
      this.groups[index].name = this.group_update_form_old_name;
      this.group_update_form_old_name = "";
      this.edit_loading = false;
    },
    removeUser(idUser) {
      CompanyGroupService.remove_member_group_company(
        this,
        this.selectedGroup,
        idUser
      ).then(result => {
        let groupIndex = this.getGroupIndex(this.selectedGroup);
        let memberIndex = this.getMemberIndex(this.selectedGroup, idUser);
        this.groups[groupIndex].members.splice(memberIndex, 1);
      });
    },
    addUser() {
      if (this.selectedUser != null) {
        CompanyGroupService.add_member_group_company(
          this,
          this.selectedGroup,
          this.selectedUser.pk
        ).then(result => {
          let groupIndex = this.getGroupIndex(this.selectedGroup);
          this.groups[groupIndex].members.push(this.selectedUser);
          this.selectedUser = null;
        });
      }
    },
    removePermission(idPermission, codename) {
      CompanyGroupService.remove_permission_group_company(
        this,
        this.selectedGroup,
        [this.get_permission(codename)],
        this.get_type_permission(codename)
      ).then(result => {
        let indexGroup = this.getGroupIndex(this.selectedGroup);
        let indexPermission = this.getIndexPermission(
          this.selectedGroup,
          idPermission
        );
        this.groups[indexGroup].permissions.splice(indexPermission, 1);
      });
    },
    removeObjectPermission(idPermission, codename, object_pk) {
      CompanyGroupService.remove_permission_group_company(
        this,
        this.selectedGroup,
        [this.get_permission(codename)],
        this.get_type_permission(codename),
        object_pk
      ).then(result => {
        let indexGroup = this.getGroupIndex(this.selectedGroup);
        let indexPermission = this.getIndexObjectPermission(
          this.selectedGroup,
          idPermission
        );
        this.groups[indexGroup].object_permissions.splice(indexPermission, 1);
      });
    }
  }
};
</script>
