import axios from 'axios'

const HMD_BACKEND_API_URL = process.env.VUE_APP_ROOT_API || 'http://localhost:8002/api'

export const HTTP = axios.create({
    baseURL: HMD_BACKEND_API_URL,
    timeout: 180000,
})

HTTP.interceptors.request.use(
    config => {
        if (localStorage.getItem('token') != null) {
            config.headers.authorization = 'Token ' + localStorage.getItem('token')
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)
