<template>
  <div class="text-center">
    <v-menu
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-bell-alert</v-icon>
        </v-btn>
      </template>
      <v-card align-center>
        <v-card-title class="primary--text">
          Mail en suivis
        </v-card-title>
        <v-card-text
          align="center"
          justify="center"
        >
          <v-list>
            <v-list-item
              v-for="mail in mails"
              :key="mail"
            >
              <template v-if="mail != ''">
                <div> {{ mail }} </div>
                <v-btn
                  icon
                  class="ml-3"
                >
                  <v-icon
                    color="red"
                    @click="deleteMail(mail)"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-else-if="mail == ''">
                <v-form
                  ref="newMailEntry"
                  v-model="valid"
                  @submit.prevent
                >
                  <v-text-field
                    v-model="addMail"
                    label="Nouveau mail"
                    :rules="emailRules"
                    required
                    @keydown.enter="addMailInput"
                  />
                </v-form>
              </template>
            </v-list-item>
          </v-list>
          <v-btn
            color="primary"
            @click="addMailEntryOrConfirm"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Ajouter un mail
          </v-btn>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

  import DatasetService from '@/services/DatasetService.js'
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      dataset: {
        type: String,
        default: '',
      },
    },
    data: () => {
      return {
        mails: [],
        addMail: '',
        emailRules: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
        valid: false,
      }
    },
    mounted () {
      if (this.dataset != '') {
        DatasetService.get(this, this.dataset, '', '').then((result) => {
          for (let i = 0; i < result.data.length; i++) {
            this.mails.push(result.data[i].mail)
          }
        })
      }
    },
    methods: {
      deleteMail (mail) {
        this.$store.commit('confirm', {
          title: 'Êtes vous sur de vouloir supprimer l\'email ?',
          message: 'Vous êtes sur le point de supprimer un mail en suivis, êtes vous sur de vouloir de le faire ?',
          color: 'warning',
          onCancel: () => {

          },
          onConfirm: () => {
            const columns = [{
              name: 'mail',
              primary_key: true,
            }]

            const row = {
              mail: mail,
            }

            DatasetService.deleteRow(this, this.dataset, row, columns).then((result) => {
              this.mails.splice(this.mails.indexOf(mail), 1)
            })
          },
        })
      },
      addMailInput (event) {
        if (this.$refs.newMailEntry[0].validate()) {
          this.$store.commit('confirm', {
            title: 'Vous êtes sur le point d\'ajouter un mail',
            message: 'Êtes vous sur de vouloir rajouter ce mail au suivis des modifications ?',
            color: 'warning',
            onCancel: () => {

            },
            onConfirm: () => {
              const row = { mail: this.addMail }
              DatasetService.create(this, this.dataset, row).then((result) => {
                this.mails.splice(this.mails.indexOf(''), 1)
                this.mails.push(this.addMail)
                this.addMail = ''
              })
            },
          })
        }
      },
      addMailEntryOrConfirm () {
        if (this.addMail != '') {
          // Demande de confirmation pour la validation du mail
          this.addMailInput(null)
        } else {
          // Ajoute un champs d'input dans la liste de mail pour rajouter un nouveau mail
          if (this.mails[this.mails.length - 1] != '') { this.mails.push('') }
        }
      },
    },
  }
</script>
