<template>
  <v-dialog scrollable v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" v-on="on" @click="open">
        <v-icon left>
          mdi-plus
        </v-icon>
        Nouveau
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="12">
            <div class="subtitle">
              Un email va étre envoyé a cet utilisateur contenant toutes les
              informations nécessaire pour se connecter.
            </div>
          </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-text-field
              @blur="$v.user.email.$touch()"
              :error-messages="errors.email"
              name="email"
              label="Email"
              outlined
              v-model="user.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-text-field
              name="first_name"
              label="Nom"
              outlined
              v-model="user.first_name"
            ></v-text-field>
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              name="first_name"
              label="Prénom"
              outlined
              v-model="user.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-select
              @blur="$v.user.group.$touch()"
              :error-messages="errors.group"
              v-model="user.group"
              :items="authorized_groups"
              outlined
              name="group"
              label="Groupe"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="close">
          Fermer
        </v-btn>
        <v-btn color="primary" @click="save" :loading="loading_save">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constant";
import CompanySelectInput from "@/components/hmd/Company/CompanySelectInput";
import CompanyService from "@/services/CompanyService";
import CompanyGroupService from "@/services/CompanyGroupService";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  validations: {
    user: {
      email: {
        required,
        email
      },
      first_name: {},
      last_name: {},
      company: { required },
      group: {
        required
      }
    },
    validationGroup: ["user"]
  },
  components: {
    CompanySelectInput
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          pk: null,
          email: "",
          first_name: "",
          last_name: "",
          company: Constant.company,
          group: null
        };
      }
    },
    authorized_groups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false,
      loading_save: false,
      user: {
        pk: null,
        email: "",
        first_name: "",
        last_name: "",
        company: Constant.company,
        group: null
      }
    };
  },
  computed: {
    formTitle() {
      return this.user.pk
        ? "Edition d'un utilisateur"
        : "Création d'un nouvel utilisateur";
    },
    errors() {
      const $ = this;
      let errs = {
        email: [],
        group: []
      };

      if ($.$v.user.email.$dirty) {
        !$.$v.user.email.required && errs.email.push("L'email est requis");
        !$.$v.user.email.email && errs.email.push("L'email doit être valide");
      }
      if ($.$v.user.group.$dirty) {
        !$.$v.user.group.required &&
          errs.group.push("L'utilisateur doit faire parti d'un groupe");
      }
      return errs;
    }
  },
  mounted() {},
  watch: {
    value(newValue, oldValue) {
      if (newValue.pk) {
        this.user = newValue;
        this.user.company = this.user.company.toLowerCase();
        this.dialog = true;
      }
    }
  },
  methods: {
    open() {
      this.$v.$reset();
    },
    reset() {
      this.user = {
        id: null,
        email: "",
        first_name: "",
        last_name: "",
        company: Constant.company,
        group: null
      };
    },
    close() {
      this.dialog = false;
      this.reset();
      this.$emit("closed");
    },
    async save() {
      this.loading_save = true;
      this.$v.user.$touch();
      if (!this.$v.user.$invalid) {
        let userformated = _.cloneDeep(this.user);
        userformated.groups = [userformated.group];
        if (this.user.pk != null) {
          let companyGroups = await CompanyGroupService.list_group_company(
            this
          );
          companyGroups = companyGroups.filter(g =>
            Constant.groups().includes(g.name)
          );
          companyGroups.forEach(g => {
            if (g.members.find(m => m.pk === this.user.pk)) {
              CompanyGroupService.remove_member_group_company(
                this,
                g.id,
                this.user.pk
              );
            }
          });
          console.log(companyGroups);
          CompanyService.User.update(this, userformated, this.user.pk)
            .then(r => {
              this.$emit("updated");
              this.close();
            })
            .finally(() => {
              this.loading_save = false;
            });
        } else {
          userformated.username = userformated.email;
          CompanyService.User.create(this, userformated)
            .then(r => {
              this.$emit("created");
              this.close();
            })
            .finally(() => {
              this.loading_save = false;
            });
        }
      } else {
        this.$store.commit("error", "L'utilisateur n'a pas pu être créé");
        this.loading_save = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
