<template>
  <v-card>
    <v-card-text>
      <v-row class="title py-5">
        <v-col cols="7">Ancienne Facture</v-col>
        <v-col cols="2">
          <v-btn color="success" @click="downloadAllFactures">
            <v-icon left>mdi-download</v-icon>
            Télécharger
          </v-btn>
        </v-col>
        <v-col cols="3">
          <upload-file-btn @reimport="getData"/>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, key) in factureGroups"
          :key="key"
        >
          <v-expansion-panel-header>
            <h4>Facture du {{ item[0].year }}/{{ item[0].month }}</h4>
            <v-btn class="align-self-end mr-4" rounded color="red" @click.native.stop="confirmDelete(item)"
                   max-width="20px"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="item"
              :headers="headers"
              disable-pagination
              hide-default-footer
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

  </v-card>
</template>

<script>
  import UploadFileBtn from '@/components/bytel/UploadFileBtn'
  import DatasetService from '@/services/DatasetService'
  import Constant from '@/constant'
  import TableauFacture from '@/components/bytel/TableauFacture'
  import QueryService from '@/services/QueryService'

  export default {
    name: 'ListFacture',
    components: {
      TableauFacture,
      UploadFileBtn,
    },
    data: () => ({
      factureGroups: {},
      mandatory_rows: [],
      headers: [],
      loading: false,
      selectedItem: null,
    }),
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        this.loading = true
        QueryService.run(this, Constant.query_all_precedent_facturation.name).then((result) => {
          //Format headers objects to be the right format for v-data-table
          this.headers = result.data.columns.map((c) => {
            let align = 'start'
            if (c.name == 'primary_key' || c.name == 'month' || c.name == 'year') {
              align = ' d-none'
            }

            return {
              text: c.name,
              value: c.name,
              align: align,
              sortable: true,
            }
          })

          // Group rows by year, month
          this.factureGroups = _.groupBy(result.data.rows, r => `${r.year}/${r.month}`)
          this.factureGroups = _.orderBy(this.factureGroups, [r => r[0].year, r => r[0].month], ['desc', 'desc'])
          this.factureGroups.forEach((g) => {
            let totalIndex = _.findIndex(g, l => l.libelle == 'Total')
            let buff = g[g.length - 1]
            g[g.length - 1] = g[totalIndex]
            g[totalIndex] = buff
          })
          this.loading = false
        })

        DatasetService.get(this, Constant.datasetFacturation.id).then((result) => {
          this.mandatory_rows = result.data.map(r => r.libelle)
        })
      },
      confirmDelete (item) {
        this.selectedItem = item
        this.$store.commit('confirm', {
          onConfirm: this.deleteOldFacture,
          message: 'Voulez-vous vraiment supprimer cette facturation ? Cette action est irréversible',
          title: 'Avertissement',
          onCancel: this.cancelDelete,
        })
      },
      cancelDelete () {
        this.selectedItem = false
      },
      deleteOldFacture (item) {
        DatasetService.get_informations(this, Constant.datasetFacturationHistorique.id).then((result) => {
          let fields = result.data.fields
          let promises = []
          this.selectedItem.forEach((r) => {
            promises.push(DatasetService.deleteRow(this, Constant.datasetFacturationHistorique.id, r, fields))
          })
          Promise.all(promises).then((result) => {
            this.$store.commit('success', 'Facture supprimée')
            this.getData()
            this.cancelDelete()
          })
        })
      },
      downloadAllFactures () {
        DatasetService.exportCsv(this, Constant.datasetFacturationHistorique.id)
      }
    },
  }
</script>

<style scoped>

</style>
