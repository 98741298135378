import Numeral from 'numeral'

Numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
})

Numeral.locale('fr')

export default Numeral
