var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.columns.filter(function (c) { return !_vm.ignore_columns.includes(c.value); }),"items":_vm.rows,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.montant",fn:function(){return [_vm._v(" quantité ")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var index = ref.index;
return [_c('tr',[_vm._l((item),function(c,i){return [(!_vm.ignore_columns.includes(i))?_c('td',[(_vm.libelles.includes(c))?[_vm._v(" "+_vm._s(c)+" ")]:(i == 'total' && item['prix_unitaire'] !== '')?[_c('b',[_vm._v(_vm._s(_vm.numeralFormat(_vm.getTotal(item))))])]:(i == 'montant' || i == 'prix_unitaire')?[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","type":"number","label":_vm.getPlaceholder(i)},model:{value:(item[i]),callback:function ($$v) {_vm.$set(item, i, $$v)},expression:"item[i]"}})]:[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","label":_vm.getPlaceholder(i)},model:{value:(item[i]),callback:function ($$v) {_vm.$set(item, i, $$v)},expression:"item[i]"}})]],2):_vm._e()]})],2)]}},{key:"body.append",fn:function(){return [_vm._l((_vm.added_lines),function(line_form,index){return _c('tr',{key:index},[_c('td',[_c('v-text-field',{attrs:{"label":"Libelle"},model:{value:(_vm.added_lines[index].libelle),callback:function ($$v) {_vm.$set(_vm.added_lines[index], "libelle", $$v)},expression:"added_lines[index].libelle"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Quantité","type":"number"},model:{value:(_vm.added_lines[index].montant),callback:function ($$v) {_vm.$set(_vm.added_lines[index], "montant", $$v)},expression:"added_lines[index].montant"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Prix unitaire","type":"number"},model:{value:(_vm.added_lines[index].prix_unitaire),callback:function ($$v) {_vm.$set(_vm.added_lines[index], "prix_unitaire", $$v)},expression:"added_lines[index].prix_unitaire"}})],1),(
              _vm.added_lines[index].montant != '' ||
                _vm.added_lines[index].prix_unitaire != ''
            )?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.numeralFormat(_vm.getTotal(_vm.added_lines[index])))+" ")]):_c('td',[_c('v-text-field',{attrs:{"label":"Total","type":"number"},model:{value:(_vm.added_lines[index].total),callback:function ($$v) {_vm.$set(_vm.added_lines[index], "total", $$v)},expression:"added_lines[index].total"}})],1)])}),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('td'),_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.numeralFormat(_vm.allTotal)))])]),_c('tr',[_c('td',[_vm._v("Recettes")]),_c('td'),_c('td'),_c('td',[_c('v-text-field',{attrs:{"label":"Recettes","type":"number"},model:{value:(_vm.recettes.montant),callback:function ($$v) {_vm.$set(_vm.recettes, "montant", $$v)},expression:"recettes.montant"}})],1)])]},proxy:true}])})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.save_loading,"disabled":!_vm.isDifferent,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Valider les modifications ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }