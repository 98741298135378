import Constant from "@/constant";

const adminBouygues = [
  {
    to: "/overview",
    icon: "mdi-home",
    text: "Accueil",
    hide: false
  },
  {
    to: "/references",
    icon: "mdi-book-multiple",
    text: "Références",
    hide: false
  },
  {
    to: "/numero_serie",
    icon: "mdi-barcode",
    text: "Suivi d'un S/N",
    hide: false
  },
  {
    to: "/alertes",
    icon: "mdi-bell",
    text: "Alertes",
    hide: false
  }
];

const adminInfonegoce = [
  ...adminBouygues,
  {
    to: "/statistiques",
    icon: "mdi-tray-full",
    text: "Statistiques",
    hide: false
  }
];

const lecteurBouygues = [
  {
    to: "/numero_serie",
    icon: "mdi-barcode",
    text: "Suivi d'un S/N",
    hide: false
  }
];

export default {
  generateLink(group) {
    switch (group) {
      case Constant.lecteur_bouygue_groupname:
        return lecteurBouygues;
      case Constant.admin_infonegoce_groupname:
        return adminInfonegoce;
      case Constant.admin_bouygue_groupname:
        return adminBouygues;
      default:
        return [];
    }
  }
};
