<template>
  <div class="mt-3">
    <h3>
      Choisissez une date de début et de fin
    </h3>
    <v-row>
      <v-col>
        <v-menu
          ref="menuDebut"
          v-model="menuDebut"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{on, attrs}">
            <v-text-field
              v-model="dateDebut"
              label="Date de début"
              preprend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateDebut"
            no-title
            scrollable
            @input="emitEvent"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateDebut = null; menuDebut = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="menuDebut = false"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="1" />
      <v-col>
        <v-menu
          ref="menuFin"
          v-model="menuFin"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template
            v-slot:activator="{on, attrs}"
          >
            <v-text-field
              v-model="dateFin"
              label="Date de fin"
              preprend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateFin"
            no-title
            scrollable
            @input="emitEvent"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateFin = null; menuFin = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="menuFin = false"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      dateFin: {
        type: String,
        default: null,
      },
      dateDebut: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        menuFin: false,
        menuDebut: false,
      }
    },
    methods: {
      emitEvent () {
        if (this.dateDebut != null && this.dateFin != null) { this.$emit('date', { dateFin: this.dateFin, dateDebut: this.dateDebut }) }
      },
    },
  }
</script>

<style>

</style>
