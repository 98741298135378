<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 fill-height" fluid>
        <v-row class="fill-height ma-0">
          <v-col
            class="imredd-bg pos-relative  px-10"
            cols="12"
            md="6"
            sm="12"
            lg="4"
            xl="4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12">
                <signup-detail
                  v-show="!invalid_key"
                  v-model="signupForm"
                  :invitation_key="$route.params.key"
                  :invited_by="inviter"
                  :user_pk="user_pk"
                />
                <invalid-invitation-key v-show="invalid_key" />
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <img
                    class="mt-2 powered_by"
                    src="@/assets/powered_by_himydata.png"
                    height="35px"
                    contain
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="bg-cover fill-height hidden-xs-only hidden-sm-only md-6 lg-8 xl-8"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AccountService from "@/services/AccountService";
import SignupDetail from "@/components/signup/SignupDetail";
import InvalidInvitationKey from "@/components/signup/InvalidInvitationKey";

export default {
  components: {
    SignupDetail,
    InvalidInvitationKey
  },
  data() {
    return {
      signupForm: {
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: ""
      },
      invalid_key: false,
      inviter: {
        name: "",
        company: ""
      },
      user_pk: -1
    };
  },
  methods: {
    init() {
      var key = this.$route.params.key;
      if (key) {
        // Check if the key is valid
        // If the key is not valid show that is not valid
        AccountService.getInvitation(this, key)
          .then(result => {
            this.signupForm.email = result.data.user.email;
            this.signupForm.username = result.data.user.username;
            this.signupForm.first_name = result.data.user.first_name;
            this.signupForm.last_name = result.data.user.last_name;
            this.inviter.name = result.data.inviter_name;
            this.inviter.company = result.data.inviter_company;
            this.user_pk = result.data.user.pk;
          })
          .catch(error => {
            console.log(error);
            this.invalid_key = true;
          });
      } else {
        this.invalid_key = true;
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style>
.imredd-bg {
  background-color: white;
}

.pos-relative {
  position: relative;
}

.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../assets/bg-cover.jpg);
}

.powered_by {
  background-color: #0055a4;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
  bottom: 15px;
  height: 45px;
  width: 200px;
}
</style>
