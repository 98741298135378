<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on }">
      <template
        v-if="!!$slots['activator']"
      >
        <slot
          name="activator"
          @click="dialog=!dialog"
        />
      </template>
      <v-btn
        outlined
        v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Fichier
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Ajout de fichiers</span>
      </v-card-title>
      <v-card-text justify="center">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                :error-messages="errors.file"
                :accept="lo.map(acceptedTypes, at => '.' + at).join(',')"
                class="files-upload"
                color="primary"
                counter
                label="Selectionnez le fichier à ajouter"
                required
                outlined

                :show-size="1000"
                @blur="$v.file.$touch()"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="primary" dark label small>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="clear" color="error">
          Annuler
        </v-btn>
        <v-btn
          :disabled="sendLoader"
          :loading="sendLoader"
          color="primary"
          depressed
          @click="submitMock"
        >
          Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DatasetService from '@/services/DatasetService'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import Constant from '@/constant'

  export default {
    mixins: [validationMixin],
    data () {
      return {
        dialog: false,
        acceptedTypes: ['xlsx', 'xls', 'csv'],
        file: null,
        sendLoader: false,
      }
    },
    validations: {
      file: { required },
    },
    computed: {
      errors () {
        const errors = {
          file: [],
        }

        if (this.$v.file.$dirty) {
          !this.$v.file.required && errors.file.push('File is required.')
        }

        return errors
      },
    },
    methods: {
      submit () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.sendLoader = true
          DatasetService.reimport(this, Constant.project, Constant.datasetFacturationHistorique.id, false, this.file).then(() => {
            this.$store.commit('success', 'Facture ajoutée')
            this.$emit('reimport')
            this.clear()
            this.sendLoader = false
          })
        }
      },
      submitMock () {
        this.sendLoader = true
        setTimeout(() => {
          this.sendLoader = false
        }, 2500)
      },
      clear () {
        this.dialog = false
        this.file = null
        this.$v.$reset()
      },
    },
  }
</script>

<style scoped>

</style>
