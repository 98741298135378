<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Réinitialiser votre mot de passe
      </v-card-title>
      <v-card-text> 
        <v-text-field
          v-model="email"
          label="E-mail"
          :error-messages="errors"
          required
          @keyup.enter="send"
        />
      </v-card-text>
      <v-card-text>
        Pour réinitialiser votre mot de passe, entrez l'adresse e-mail que vous
        utilisez et nous vous enverrons des instructions supplémentaires par
        e-mail.
        </v-card-text
      >
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="
            dialog = false;
            $v.$reset();
          "
        >
          Annuler
        </v-btn>
        <v-btn depressed @click="send" color="primary--text">
          Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AccountService from "@/services/AccountService";
export default {
  mixins: [validationMixin],
  validations: {
    email: { email, required }
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: () => "Mot de passe oublié ?"
    }
  },
  data() {
    return {
      dialog: false,
      email: ""
    };
  },
  watch: {
    value: function(newVal) {
      this.email = newVal;
    }
  },
  computed: {
    errors() {
      const errors = [];
      if (this.$v.email.$dirty) {
        if (!this.$v.email.email) {
          errors.push("Must be valid e-mail");
        }
        if (!this.$v.email.required) {
          errors.push("E-mail is required");
        }
      }

      return errors;
    }
  },
  methods: {
    send() {
      const self = this;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        AccountService.resetPassword(self.email, self).then(() => {
          self.dialog = false;
        });
      }
    }
  }
};
</script>
