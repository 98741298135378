module.exports = {
  app: {
    name: "BTE APP by Himydata",
    description:
      "",
    url: ""
  },
  drawer: {
    elements: [
      {
        to: "/overview",
        icon: "mdi-view-dashboard",
        text: "Dashboard"
      }
    ],
    isDark: true,
    bgColor: {
      admin: "#0E264A",
      default: "#004E7D"
    }
  }
};
