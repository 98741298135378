import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

Vue.use(VueLodash, { name: 'lo', lodash: lodash })

sync(store, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
