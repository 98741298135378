<template>
  <v-card class="elevation-0">
    <v-card-title>Événement</v-card-title>
    <v-card-text class="d-flex justify-center">
      <v-expansion-panels inset multiple v-model="panel">
        <v-expansion-panel v-for="(event, index) in events" :key="index">
          <v-expansion-panel-header class="subtitle-1">Événement du {{ event.date }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="10" sm="7" md="6">
                <v-row>
                  <v-col cols="12" v-for="(e, index) in event.events" :key="index">
                    <v-icon left :color="e.color">{{ e.icon }}</v-icon>
                    {{ e.message }}
                  </v-col>
                </v-row>
              </v-col>
              <v-divider v-show="!$vuetify.breakpoint.xsOnly" sm="1" md="2" class="mr-4" vertical/>
              <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'mt-3' : ''"
                     class="align-self-center justify-center text-center d-flex flex-column"
              >
                <b>Télécharger le fichier</b>
                <v-btn block outlined class="mt-3" color="success darken-1" width="fit-content" @click="downloadMock"
                       :loading="loading"
                >
                  <v-icon left>mdi-download</v-icon>
                  Télécharger
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'EventExpandPanel',
    data: function () {
      return {
        loading: false,
        panel: [0],
        events: [
          {
            date: '22/03/2021',
            events: [
              {
                icon: 'mdi-mail',
                message: 'Email envoyé aux utilisateurs Infonegoce',
                color: 'primary',
              }, {
                icon: 'mdi-file',
                message: 'Fichier uploadé par l\'utilisateur admin Bouygues',
                color: 'yellow darken-3',
              },
            ],
            fileLink: '',
          },
          {
            date: '03/03/2021',
            events: [
              {
                icon: 'mdi-mail',
                message: 'Email envoyé aux utilisateurs Infonegoce',
                color: 'primary',
              }, {
                icon: 'mdi-file',
                message: 'Fichier uploadé par l\'utilisateur admin Bouygues',
                color: 'yellow darken-3',
              },
            ],
            fileLink: '',
          },
          {
            date: '20/02/2021',
            events: [
              {
                icon: 'mdi-mail',
                message: 'Email envoyé aux utilisateurs Infonegoce',
                color: 'primary',
              }, {
                icon: 'mdi-file',
                message: 'Fichier uploadé par l\'utilisateur admin Bouygues',
                color: 'yellow darken-3',
              },
            ],
            fileLink: '',
          },
          {
            date: '02/02/2021',
            events: [
              {
                icon: 'mdi-mail',
                message: 'Email envoyé aux utilisateurs Infonegoce',
                color: 'primary',
              }, {
                icon: 'mdi-file',
                message: 'Fichier uploadé par l\'utilisateur admin Bouygues',
                color: 'yellow darken-3',
              },
            ],
            fileLink: '',
          },
        ],
      }
    },
    methods: {
      downloadMock () {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2500)
      },
    },
  }
</script>

<style scoped>
</style>
