var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',[_c('v-row',[_c('v-text-field',{attrs:{"placeholder":"Recherche","append-icon":"mdi-magnify"},model:{value:(_vm.recherche),callback:function ($$v) {_vm.recherche=$$v},expression:"recherche"}})],1),_c('v-row',{staticClass:"xs"},[_c('hmd-table',{ref:"tableReference",attrs:{"title":"Référence","recherche":_vm.recherche,"card":"","paginate":"","conditional-format":[
          {
            cols: ['reference'],
            text: 'Référence',
            condition: function (v) { return v > 40; },
            style: {}
          },
          {
            cols: ['destination'],
            text: 'Destination',
            condition: function (v) { return v > 40; },
            style: {}
          },
          {
            cols: ['libelle'],
            text: 'Désignation',
            condition: function (v) { return v > 40; },
            style: {}
          },
          {
            cols: ['destination frd', 'destination_frd'],
            text: 'Destination frd',
            condition: function (v) { return v > 40; },
            style: {}
          },
          {
            cols: ['gencode'],
            text: 'Gencode',
            condition: function (v) { return v > 40; },
            style: {}
          },
          {
            cols: ['qte a renvoyer', 'qte_a_renvoyer'],
            text: 'Quantité',
            condition: function (v) { return v > 40; },
            style: {}
          }
        ],"dataset":_vm.datasets.article_bt,"ignored-columns":_vm.ignoredColumns,"iseditable":_vm.modification},on:{"modification":_vm.modificationEvent}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }