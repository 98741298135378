<template>
  <v-card
    :elevation="card ? 1 : 0"
    height="100%"
    width="100%"
  >
    <v-card-title v-if="title">
      <v-row>
        <v-col sm="6">
          <div class="subtitle-1 font-weight-bold">
            {{ title }}
          </div>
        </v-col>
        <v-col
          v-if="mailingList"
          sm="1"
          md="2"
          lg="4"
        >

          <mailing-list
            title="Mail en suivis"
            :dataset="mailingList"
          >
            />
          </mailing-list>
        </v-col>
        <v-col
          v-if="
            iseditable"
          sm="4"
          md="3"
          lg="1"
        >
          <v-btn
            small
            :color="(modification) ? 'accent' : ''"
            :style="{'position': 'absolute', 'right':'85px'}"
            @click="enableModification"
            outlined
          >
            <v-icon left>
              mdi-pen
            </v-icon>
            Modification
          </v-btn>
        </v-col>
        <v-col sm="1">
          <v-btn
            icon
            :style="{'position': 'absolute', 'right':'15px'}"
            @click="contentHidden = !contentHidden"
          >
            <v-icon>mdi-chevron-{{ contentHidden ? 'down' : 'up' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-dialog v-model="openDialog" width="450px">
      <v-card>
        <v-card-title>
          Nouvelle valeur
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dialogValue"
            type="text"
            placeholder="Nouvelle valeur..."
            label="valeur"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="error"
            @click="cancelModifyValue"
          >Annuler
          </v-btn>
          <v-btn
            color="success"
            @click="modifyValue"
          >Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expand-transition>

      <v-card
        elevation="0"
        outlined
      >
        <v-card-text
          v-show="!contentHidden"
          class="pa-0"
        >
          <v-data-table
            outline
            :hide-default-footer="!paginate"
            :headers="table.columns"
            :items="table.rows"
            :search="recherche"
          >
            <template v-slot:item="{ item, headers, index }">
              <tr>
                <template v-for="(c, i) in item">
                  <td
                    v-if="!ignoredColumns.includes(i)"
                    :key="i"
                    :style="evaluateStyle(item, headers, i)"
                    class="text-left"
                  >
                    <template v-if="modification && !blockedColumns.includes(i)">
                      <v-text-field
                        @click="openPopinModifyValue(item['primary_key'], i, c)"
                        outlined
                        dense
                        v-model="item[i]"
                        class="mt-5"
                      >
                        {{ item[i] }}
                      </v-text-field>
                    </template>
                    <template v-else-if="isNaN(c)">
                      {{ c }}
                    </template>
                    <template v-else-if="formatType == 'percentage'">
                      {{ c || 0 }} %
                    </template>
                    <template v-else-if="formatType == 'currency'">
                      {{ c | currency }}
                      €
                    </template>
                    <template v-else>
                      {{ c }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import MailingList from '@/components/hmd/MailingList.vue'
  import Numeral from '@/plugins/numeral'
  import QueryService from '@/services/QueryService.js'
  import DatasetService from '@/services/DatasetService.js'

  export default {
    components: {
      MailingList,
    },
    filters: {
      currency: function (value) {
        return Numeral(value).format()
      },
    },
    props: {
      ignoredColumns: {
        type: Array,
        default: () => [],
      },
      paginate: {
        type: Boolean,
        default: false,
      },
      card: {
        type: Boolean,
        default: false,
      },
      conditionalFormat: {
        type: Array,
        default: () => [],
      },
      formatType: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      queryName: {
        type: String,
        default: null,
      },
      recherche: {
        type: String,
        default: '',
      },
      iseditable: {
        type: Boolean,
        default: false,
      },
      mailingList: {
        type: String,
        default: null,
      },
      unsavedQueryData: {
        type: Object,
        default: () => {
          return {
            rows: [],
            columns: [],
          }
        },
      },
      dataset: {
        type: String,
        default: null,
      },
      datasetFilter: {
        type: Object,
        default: null,
      },
      blockedColumns: {
        type: Array,
        default: ()=>([])
      }
    },
    data () {
      return {
        contentHidden: false,
        table: {
          rows: [],
          columns: [],
        },
        modification: false,
        oldTableData: {},
        dialogValue: '',
        valueToModify: {
          id: null,
          column: '',
        },
        openDialog: false,
        isDifferent: false,
      }
    },
    computed: {
      different () {
        let buff = []
        for (let i = 0; i < this.table.rows.length; i++) {
          for (let j = 0; j < Object.keys(this.table.rows[i]).length; j++) {
            const key = Object.keys(this.table.rows[i])[j]
            if (this.table.rows[i][key] !== this.oldTableData.rows[i][key]) {
              buff.push(this.table.rows[i])
            }
          }
        }
        return buff
      },
    },
    watch: {
      queryName () {
        this.get()
      },
      unsavedQueryData () {
        if ('columns' in this.unsavedQueryData) {
          if (this.unsavedQueryData.columns.length > 0) {
            this.render(this.unsavedQueryData)
          }
        }
      },
      table: {
        deep: true,
        handler: function (newVal) {
          this.isDifferent = !_.isEqual(this.table.rows, this.oldTableData.rows)
          this.$emit('modification', this.isDifferent)
        },
      },
    },
    mounted () {
      if (this.queryName != null || this.dataset != null) {
        this.get()
      }
    },
    methods: {
      evaluateStyle (item, headers, index) {
        const $ = this
        const conditionalFormat = $.lo.find(headers, v => v.value === index)
          .conditionalFormat
        if (conditionalFormat.condition(item[index])) {
          return conditionalFormat.style
        } else {
          return {}
        }
      },
      get () {
        const $ = this
        if ($.queryName) {
          QueryService.run($, $.queryName).then(r => {
            this.render(r.data)
          })
        } else if (this.dataset != null) {
          DatasetService.get(this, this.dataset, this.datasetFilter, '').then((result) => {
            const data = {
              columns: [],
              rows: [],
            }
            if (result.data.length > 0) {
              // Set des columns
              for (let i = 0; i < Object.keys(result.data[0]).length; i++) {
                const key = Object.keys(result.data[0])[i]
                data.columns.push({ name: key })
              }

              data.rows = result.data
            }

            this.render(data)
          })
        }
      },
      render (data) {
        const $ = this
        data.columns = data.columns.map(c => {
          const condFormats = $.lo.filter($.conditionalFormat, f =>
            f.cols.includes(c.name),
          )
          return {
            text: condFormats.length > 0 ? condFormats[0].text : $.lo.lowerCase(c.name),
            value: c.name,
            class: $.ignoredColumns.includes(c.name) ? 'd-none' : '',
            align: 'left',
            sort: false,
            type: c.type,
            conditionalFormat:
              condFormats.length > 0
                ? condFormats[0]
                : {
                  condition: v => false,
                  style: {},
                },
          }
        })
        $.table = data
        $.oldTableData = _.cloneDeep($.table)
      },
      enableModification () {
        const $ = this
        if (this.modification === false) {
          this.$store.commit('confirm', {
            title: 'Activation des modifications',
            message: 'Vous êtes entrain d\'activer les modifications, toutes modifications modifiera les données. Êtes vous sur de vouloir continuer ?',
            color: 'warning',
            onCancel: () => {

            },
            onConfirm: () => {
              // On active le mode modification
              // This tricks is to create a copy of this.table's data (and not copy the pointer)
              $.oldTableData = _.cloneDeep($.table)
              $.modification = !$.modification
            },
          })
        } else {
          // On sauvegarde
          if (this.different.length > 0) {
            this.$store.commit('confirm', {
              title: 'Modification en cours',
              message: 'Vous avez modifié des données, êtes vous sur de vouloir les enregistrer ?',
              color: 'warning',
              onCancel: () => {
                this.reset()
              },
              onConfirm: () => {
                // Update du backend
                this.save()
              },
            })
          }
          else{
            this.modification = false
          }
        }
      },
      openPopinModifyValue (pk, column, c) {
        this.openDialog = true
        this.valueToModify.id = pk
        this.valueToModify.column = column
        this.dialogValue = c
      },
      modifyValue () {
        this.table.rows.filter(row => row['primary_key'] === this.valueToModify.id)[0][this.valueToModify.column] = this.dialogValue
        this.openDialog = false
        this.dialogValue = ''
        this.valueToModify.id = null
        this.valueToModify.column = ''
      },
      cancelModifyValue () {
        this.openDialog = false
        this.valueToModify.id = null
        this.valueToModify.column = ''
        this.dialogValue = ''
      },
      async save () {
        let $ = this
        const fields = []
        for (let i = 0; i < $.table.columns.length; i++) {
          fields.push($.table.columns[i].text)
        }

        let promises = []
        for (let i = 0; i < this.different.length; i++) {
          promises.push(DatasetService.update($, $.dataset, this.different[i], fields, false))
        }
        await Promise.all(promises)
        $.$store.commit('success', 'La mise à jour des données a été effectué')
        this.modification = false
      },
      reset () {
        this.modification = false
        this.table = _.cloneDeep(this.oldTableData)
      },
    },

  }
</script>
