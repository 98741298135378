<template>
  <v-card :elevation="card ? 1 : 0">
    <v-card-text class="pa-0">
      <apexchart
        :ref="ref"
        height="250"
        width="100%"
        :type="type"
        :options="options"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import Numeral from '@/plugins/numeral'
  import QueryService from '@/services/QueryService.js'
  import VueApexCharts from 'vue-apexcharts'
  import DatasetService from '@/services/DatasetService.js'

  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      ignoredColumns: {
        type: Array,
        default: () => [],
      },
      yAxis: {
        type: String,
        default: false,
      },
      xAxis: {
        type: String,
        default: false,
      },
      groupBy: {
        type: String,
        default: null,
      },
      mainColumn: {
        type: String,
        default: null,
      },
      card: {
        type: Boolean,
        default: false,
      },
      queryName: {
        type: String,
        default: null,
      },
      parameters: {
        type: Array,
        default: () => ([]),
      },
      type: {
        type: String,
        default: 'bar',
      },
      dataset: {
        type: String,
        default: null,
      },
      datasetFilter: {
        type: Object,
        default: null,
      },
      unsavedQueryData: {
        type: Object,
        default: () => {
          return {
            rows: [],
            columns: [],
          }
        },
      },
    },
    data () {
      const palettes = ['#009DCC', '#FF9F00', '#820263', '#EB4952', '#4EA699', '#ff9933', '#0055A4', '#A6DDED']
      return {
        ref: 'char-' + Date.now(),
        series: [
          {
            name: 'Total',
            data: [],
          },
        ],
        pieOptions: {
          colors: palettes,
        },
        barOptions: {
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
            },
          },
          dataLabels: {
            position: 'top',
            enabled: true,
            dropShadow: {
              enabled: true,
              left: 0,
              top: 0,
              opacity: 0.5,
            },
            formatter: () => {
            },
          },
          legend: {
            position: 'bottom',
          },
          yaxis: {
            labels: {
              formatter: v => v,
            },
          },
          xaxis: {
            categories: [],
          },
          colors: palettes,
        },
      }
    },
    computed: {
      options () {
        return this.type == 'pie' ? this.pieOptions : this.barOptions
      },
    },
    watch: {
      queryName () {
        if (this.queryName != null) {
          this.get()
        }
      },
      parameters: {
        deep: true,
        immediate: true,
        handler: function (newVal) {
          this.parameters = newVal
          this.get()
        },
      },
      dataset () {
        if (this.dataset != null) {
          this.get()
        }
      },
      datasetFilter () {
        if (this.datasetFilter != null) {
          this.get()
        }
      },
      unsavedQueryData () {
        if ('columns' in this.unsavedQueryData) {
          if (this.unsavedQueryData.columns.length > 0) {
            this.render(this.unsavedQueryData)
          }
        }
      },
    },
    mounted () {
      const $ = this

      if ($.type == 'bar') {
        if ($.type == 'currency') {
          $.options.yaxis.labels.formatter = v => Numeral(v).format() + ' €'
          $.options.dataLabels.formatter = v => Numeral(v).format() + ' €'
        } else {
          $.options.yaxis.labels.formatter = v => Numeral(v).format()
          $.options.dataLabels.formatter = v => Numeral(v).format()
        }
      }

      $.get()
    },
    methods: {
      handlePieChartData (data) {
        const $ = this
        $.series = $.lo.map(data.rows, $.yAxis)
        $.options.labels = $.lo.map(data.rows, $.xAxis)
        this.$refs[$.ref].updateOptions($.options)
      },
      handleBarChartData (data) {
        const $ = this
        if ($.xAxis) {
          let categories = _.map(data.rows, $.xAxis)
          categories = _.uniq(categories)
          $.options.xaxis = {
            categories: categories,
          }
          $.series = $.lo.map(
            data.columns.filter(
              v => v.name !== $.xAxis && !$.ignoredColumns.includes(v.name),
            ),
            c => {
              return {
                name: c.name,
                data: $.lo.map(data.rows, r => {
                  return r[c.name]
                }),
              }
            },
          )
        } else {
          $.options.xaxis = {
            categories: ['Montant'],
          }
          $.series = $.lo.map(data.columns, c => {
            return {
              name: c.name,
              data: [data.rows[0][c.name]],
            }
          })
        }

        if ($.groupBy) {
          // Regroup all categories, with an array of index, that indicate which index is in which category
          let groups = $.series.find((s) => s.name == $.groupBy).data
          groups = groups.map((d, i) => {
            return {
              category: d,
              index: i,
            }
          })
          groups = _.groupBy(groups, 'category')
          let buff = {}
          Object.keys(groups).forEach((k) => {
            buff[k] = groups[k].map((g) => g.index)
          })
          groups = buff

          //Create the grouped series that will replace series
          let buff_series = Object.keys(groups).map((k) => {
            return {
              name: k,
              data: [],
              type: 'line',
            }
          })
          //Change mainColumn type to column
          buff_series.find((s) => s.name == $.mainColumn).type = 'column'

          // Put each value in the corresponding series
          let main_serie = $.series.find((s) => s.name == $.yAxis).data
          main_serie.forEach((d, i) => {
            let serie = Object.keys(groups).filter((g) => groups[g].includes(i))[0]
            buff_series.find((s) => s.name == serie).data.push(d)
          })

          //Order series
          let main_index = _.findIndex(buff_series, (s) => s.name == $.mainColumn)
          buff = buff_series[main_index]
          buff_series[main_index] = buff_series[0]
          buff_series[0] = buff

          // Replace series with our grouped series
          $.series = buff_series
        }

        this.$refs[$.ref].updateOptions($.options)
      },
      get () {
        if (this.queryName) {
          QueryService.run(this, this.queryName, this.parameters).then(r => {
            this.render(r.data)
          })
        } else if (this.dataset) {
          /**
           * Permet d'utiliser le Dataset plutôt que la query, plus utile dans certains cas
           */
          const param = (this.datasetFilter) ? this.datasetFilter : ''
          const header = ''
          DatasetService.get(this, this.dataset, param, header).then((result) => {
            // Traitement de la data pour avoir column [x, y]
            // row [{x, y},...]
            const column = [{ name: this.xAxis }, { name: this.yAxis }]
            const rows = []
            for (let elem in result.data) {
              elem = result.data[elem]
              const temp = {}
              temp[column[0].name] = elem[column[0].name]
              temp[column[1].name] = elem[column[1].name]
              rows.push(temp)
            }

            const data = {
              rows: rows,
              columns: column,
            }
            this.render(data)
          }).catch((error) => {
            this.$store.commit('error', {
              msg: error,
              color: 'error',
            })
          })
        }
      },
      render (data) {
        switch (this.type) {
          case 'bar':
          case 'line':
            this.handleBarChartData(data)
            break
          case 'pie':
            this.handlePieChartData(data)
            break
          default:
            break
        }
      },
    },
  }
</script>

<style scoped></style>
