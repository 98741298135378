<template>
  <v-card>
    <v-card-title>
      <div class="subtitle-1 font-weight-bold">
        {{ title }}
      </div>
      <v-btn
        icon
        :style="{ position: 'absolute', right: '15px' }"
        @click="contentHidden = !contentHidden"
      >
        <v-icon>mdi-chevron-{{ contentHidden ? "down" : "up" }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <v-card-text
        v-show="!contentHidden"
        class="pa-0"
      >
        <hmd-chart
          :ignored-columns="ignoredColumns"
          :type="chartType || 'bar'"
          :x-axis="xAxis"
          :y-axis="yAxis"
          :groupBy="groupBy"
          :mainColumn="mainColumn"
          :query-name="queryName"
          :parameters="parameters"
          :dataset="dataset"
          :dataset-filter="datasetFilter"
          :unsaved-query-data="unsavedQueryData"
        />
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import Table from '@/components/bnc/dashboard/Table'
  import Chart from '@/components/bnc/dashboard/Chart'
  export default {
    components: {
      'hmd-table': Table,
      'hmd-chart': Chart,
    },
    props: {
      ignoredColumns: {
        type: Array,
        default: () => [],
      },
      chartType: {
        type: String,
        default: null,
      },
      xAxis: {
        type: String,
        default: null,
      },
      yAxis: {
        type: String,
        default: null,
      },
      groupBy: {
        type: String,
        default: null
      },
      mainColumn: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null,
      },
      queryName: {
        type: String,
        default: null,
      },
      formatType: {
        type: String,
        default: null,
      },
      dataset: {
        type: String,
        default: null,
      },
      datasetFilter: {
        type: Object,
        default: null,
      },
      unsavedQueryData: {
        type: Object,
        default: () => {
          return { rows: [], columns: [] }
        },
      },
      parameters: {
        type: Array,
        default: ()=>([])
      }
    },
    data () {
      return {
        contentHidden: false,
      }
    },
  }
</script>

<style scoped>
.subtitle-1{
  font-family: 'Open Sans', sans-serif;
  color: #004480
}
</style>
