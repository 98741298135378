<template>
  <v-container>
    <div>Page en cours de développement...</div>
    <v-card class="my-3">
      <v-card-title>Chargement des fichiers</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12"><p>Le dernier chargement de fichiers a été effectué le : 23/03/2021</p></v-col>
          <v-col cols="12"><p>Vous pouvez charger un nouveau fichier en cliquant sur le bouton ci-dessous<br>Un mail
            sera automatiquement envoyé aux membres d'Infonegoce</p></v-col>
          <v-col cols="12">
            <upload-file-btn/>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <event-expand-panel/>
  </v-container>
</template>

<script>
  import EventExpandPanel from '@/components/bytel/EventExpandPanel'
  import UploadFileBtn from '@/components/bytel/UploadFileBtn'

  export default {
    components: {
      UploadFileBtn,
      EventExpandPanel,
    },
    data () {
      return {}
    },
  }
</script>

<style>

</style>
