<template>
  <v-app>
    <core-app-bar/>

    <core-drawer/>

    <core-view marging=""/>
    <!--     <core-footer /> -->
    <v-offline @detected-condition="amIOnline"/>
    <v-snackbar
      :value="!$store.state.isOnline"
      color="error"
      :timeout="0"
      top
    >
      <v-icon
        color="white"
        left
      >
        mdi-earth-off
      </v-icon>
      You are offline, please check your internet connection !
    </v-snackbar>
    <error-handler/>
    <notify ref="notify"/>
    <confirm ref="confirm"/>
  </v-app>
</template>

<script>
  import Notify from '@/components/utils/Notify'
  import Confirm from '@/components/utils/Confirm'
  import ErrorHandler from '@/components/utils/ErrorHandler'
  import VOffline from 'v-offline'
  import DrawerLinks from '@/utils/drawer-links'

  export default {
    components: {
      VOffline,
      ErrorHandler,
      CoreDrawer: () => import('@/components/layouts/core/Drawer'),
      /* CoreFooter: () => import('@/components/layouts/core/Footer'), */
      CoreAppBar: () => import('@/components/layouts/core/AppBar'),
      CoreView: () => import('@/components/layouts/core/View'),
      Confirm,
      Notify,
    },
    data: () => ({
      onLine: true,
    }),
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) this.$store.commit('setUser', user)
      this.redirect()
    },
    methods: {
      amIOnline (e) {
        this.$store.commit(e ? 'online' : 'offline')
      },
      redirect () {
        let group = this.$store.getters.group
        if (!group) {
          this.$router.push('/signin')
        }
        let links = DrawerLinks.generateLink(group)
        links = links.map(l => l.to)
        if (!links.includes(this.$router.currentRoute.path)) {
          this.$router.push(links[0])
        }
      },
    },
  }
</script>
