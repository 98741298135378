<template>
  <v-card
    :loading="loading"
    width="100%"
    height="auto"
    class="text-center pa-7"
  >
    <div class="value display-1 font-weight-bold primary--text">
      <template v-if="valueType == 'currency'">
        {{ val | currency }} €
      </template>
      <template v-else>
        {{ val }}
      </template>
    </div>
    <div class="subtitle-1 mt-2 font-weight-light display-inline text-break black--text">
      {{ label }}
    </div>
  </v-card>
</template>

<script>
import Numeral from "@/plugins/numeral";
import QueryService from "@/services/QueryService.js";
export default {
  props: {
    label: {
      type: String,
      default: null,
      required: true
    },
    queryName: {
      type: String,
      default: null,
      required: true
    },
    valueType: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null,
      required: true
    },
    lastRow: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  filters: {
    currency: function(value) {
      return Numeral(value).format();
    }
  },
  data() {
    return {
      loading: false,
      val: "-"
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      const $ = this;
      $.loading = true;
      if ($.queryName) {
        QueryService.run($, $.queryName).then(r => {
          r = r.data;
          if (r.rows.length > 0 && this.lastRow == false) {
            if (r.rows[0][$.value]) $.val = r.rows[0][$.value];
          }
          else if(r.rows.length > 0 && this.lastRow) {
            let index = (r.rows.length-1);
            if (r.rows[index][$.value]) $.val = r.rows[index][$.value];
          }
          else;
          $.loading = false;
        });
      }
    }
  }
};
</script>

<style scoped>
.subtitle-1{
  font-family: 'Open Sans', sans-serif;
  color: #004480
}
</style>
