<template>
  <v-select
    :items="companies"
    v-model="v"
    item-text="name"
    placeholder="Choisissez un marchand..."
    return-object
    @keyup.enter="create()"
    @input="$emit('input', v.slug)"
    @blur="$emit('blur')"
    @change="$emit('change', v.slug)"
    :error-messages="errorMessages"
    outlined
    @click:clear="
      $emit('input', '');
      v = undefined;
    "
  ></v-select>
  <!-- <v-combobox
    clearable
    outlined
    v-model="v"
    item-text="name"
    return-object
    :filter="filter"
    :error-messages="errorMessages"
    :items="
      [
        {
          header: 'Selectionner un marchand ou saisissez un nom et tapez ENTREE'
        }
      ].concat(companies)
    "
    :search-input.sync="search"
    :hide-no-data="!search"
    label="Marchand"
    placeholder="Choisissez un marchand..."
    @keyup.enter="create()"
    @input="$emit('input', v.slug)"
    @blur="$emit('blur')"
    @change="$emit('change', v.slug)"
    @click:clear="
      $emit('input', '');
      v = undefined;
    "
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading mr-1">Pour créer</span>
        <v-chip color="primary lighten-3`" label small>
          {{ search }}
        </v-chip>
        <span class="subheading ml-1">tapez ENTREE</span>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      {{ item.name }}
    </template>
    <template v-slot:item="{ index, item }">
      <v-text-field
        v-if="editing === item"
        v-model="editing.name"
        autofocus
        flat
        background-color="transparent"
        hide-details
        solo
        @keyup.enter.prevent="edit(index, item)"
      ></v-text-field>
      <v-chip v-else color="grey lighten-3" label>
        {{ item.name }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action class="text-right" @click.stop style="width:85px;">
        <span>
          <v-btn
            title="Edit this company"
            class="mr-2 elevation-2"
            icon
            :color="editing === item ? 'success' : ''"
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
          </v-btn>
          <v-btn
            :disabled="item.users.length > 0"
            title="Delete this company"
            class="elevation-2"
            icon
            color="red lighten-1 "
            @click.stop.prevent="remove(index, item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </span>
      </v-list-item-action>
    </template>
  </v-combobox> -->
</template>

<script>
import CompanyService from "@/services/CompanyService";
export default {
  props: {
    errorMessages: {
      type: Array,
      default: []
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      initiated: false,
      v: {},
      companies: [],
      editing: null,
      index: -1,
      search: null
    };
  },
  mounted() {
    this.v = this.lo.clone(this.value);
    this.get();
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue) {
        let found = this.companies.find(v => v.slug == newValue);
        if (found) this.v = this.lo.cloneDeep(found);
      } else this.v = {};
    }
  },
  methods: {
    remove(index, item) {
      index = this.companies.indexOf(item);
      if (confirm("Êtes-vous sûr de vouloir supprimer cet élement ?")) {
        CompanyService.delete(this, this.companies[index].id).then(() => {
          this.companies.splice(index, 1);
        });
      }
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.index = index;
      } else {
        this.editing.slug = this.lo.kebabCase(this.editing.name);
        CompanyService.update(this, this.editing, this.editing.id).then(r => {
          this.$emit("input", this.v.slug);
          this.$emit("updatedCompany", this.v);
          this.editing = null;
          this.index = -1;
        });
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    create() {
      CompanyService.create(this, this.v).then(r => {
        this.companies.unshift(r);
        this.v = r.slug;
        this.$emit("input", this.v.slug);
      });
    },
    get() {
      CompanyService.list(this).then(r => {
        if (!this.initiated) this.initiated = true;
        this.companies = r.results;
      });
    }
  }
};
</script>

<style scoped></style>
