<template>
  <v-data-table
    :loading="is.loading"
    :headers="table.cols"
    :items="table.rows"
    :fixed-header="true"
    :search="searchInput"
    :height="fillHeight ? height : 'auto'"
    class="elevation-3"
  >
    <template v-slot:top>
      <v-toolbar flat color="white" height="90">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                v-model="searchInput"
                placeholder="Recherche..."
              />
            </v-col>
            <v-col cols="8" class="text-right">
              <user-create-update-dialog
                v-model="editedItem"
                :authorized_groups="authorized_groups"
                @created="get"
                @updated="get"
                @closed="
                  editedIndex = -1;
                  editedItem = {};
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn
        rounded
        fab
        color="primary"
        class="mr-3"
        text
        x-small
        @click="editItem(item)"
      >
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        rounded
        fab
        dark
        color="red"
        text
        x-small
        @click="deleteItem(item)"
      >
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import CompanyService from "@/services/CompanyService";
import Constant from "@/constant";
import UserCreateUpdateDialog from "@/components/hmd/Users/UserCreateUpdateDialog";

export default {
  components: {
    UserCreateUpdateDialog
  },
  props: {
    fillHeight: {
      type: Boolean,
      default: false
    },
    authorized_groups: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    height: 500,
    searchInput: "",
    table: {
      rows: [],
      cols: [
        {
          text: "Utilisateur",
          value: "username",
          sort: false
        },
        {
          text: "Email",
          value: "email",
          sort: false
        },
        {
          text: "Nom",
          value: "first_name",
          sort: false
        },
        {
          text: "Prénom",
          value: "last_name",
          sort: false
        },
        {
          text: "Groupe",
          value: "group",
          sort: false
        },
        {
          text: "Actions",
          value: "action",
          sort: false
        }
      ]
    },
    editedIndex: -1,
    editedItem: {},
    is: {
      loading: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    isActionsDisabled() {
      return this.is.loading;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  mounted() {
    const $ = this;
    this.get();
    $.height = window.innerHeight - 300;
    window.addEventListener("resize", e => {
      $.height = window.innerHeight - 300;
    });
  },

  methods: {
    get() {
      this.is.loading = true;
      return CompanyService.get(this, Constant.company).then(r => {
        r.users = r.users.map(u => {
          u.company = Constant.company;
          u.group = Constant.groups().filter(g => {
            return u.groups.includes(g);
          })[0];
          return u;
        });
        if (this.authorized_groups.length > 0) {
          r.users = r.users.filter(u => {
            if (u.groups.length === 0) return true;
            else {
              for (let g of u.groups) {
                if (this.authorized_groups.includes(g)) return u;
              }
            }
          });
        }
        this.table.rows = r.users;
        this.is.loading = false;
      });
    },

    editItem(item) {
      this.editedIndex = this.table.rows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
    },

    deleteItem(item) {
      const index = this.table.rows.indexOf(item);
      if (confirm("Êtes-vous sûr de vouloir supprimer cet élement ?")) {
        CompanyService.User.delete(this, item.pk).then(() => {
          this.table.rows.splice(index, 1);
        });
      }
    }
  }
};
</script>

<style scoped></style>
