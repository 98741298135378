<template>
  <v-app>
    <v-content>
      <v-container class="pa-0 fill-height" fluid>
        <v-row class="fill-height pa-0 ma-0">
          <v-col cols="12" md="6" sm="12" lg="4" xl="4">
            <v-row class="pa-7 fill-height" align="center" justify="center">
              <v-col class="pa-3" cols="12">
                <img
                  src="@/assets/logo-mfm.png"
                  class="logo-bte"
                />

                <h1 class="font-weight-light mt-5">Bienvenue</h1>
                <span class="black--text mt-2"
                  >Connectez-vous à la plateforme de suivi des flux
                  matériels</span
                >
                <v-form>
                  <v-text-field
                    v-model="email"
                    name="email"
                    placeholder="Adresse email"
                    :error-messages="errors.email"
                    autofocus
                    class="input-group--focused mt-5"
                    @blur="$v.email.$touch()"
                  />
                  <password-field v-model="password" name="password" />
                  <v-btn
                    dark
                    large
                    block
                    :disabled="false"
                    color="primary white--text"
                    class="my-3 redbouygue"
                    @click="submit"
                    @keyup.enter="submit"
                  >
                    Connexion
                  </v-btn>
                </v-form>
                <!-- <v-btn dark large block @click="submit" @keyup.enter="submit" disabled color="pink white--text" class="my-3" >Sign in</v-btn> -->
                <reset-password v-model="email" />
              </v-col>
            </v-row>
          </v-col>
          <!-- <img
            class="mt-2 powered_by"
            src="@/assets/powered_by_himydata.png"
            height="25px"
            contain
          /> -->
          <v-col class="bg-cover hidden-xs-only hidden-sm-only md-6 lg-8 xl-8">
            <p class="display-1 lightheader">
              Plateforme de suivi des flux de matériels
            </p>
          </v-col>
        </v-row>
        <error-handler />
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import ResetPassword from "@/components/utils/ResetPassword";
// import ResendActivationEmail from "@/components/utils/ResendActivationEmail";
import ErrorHandler from "@/components/utils/ErrorHandler";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { login } from "@/utils/auth";
import Constant from "@/constant";
import PasswordField from "@/components/base/PasswordField";

const Config = require("@/utils/theme-config");
export default {
  components: {
    PasswordField,
    ErrorHandler,
    // ResendActivationEmail,
    ResetPassword
  },
  mixins: [validationMixin],
  validations: {
    password: { required },
    email: {
      email,
      required
    }
  },
  data: function () {
    return {
      app: {
        name: Config.app.name,
        url: Config.app.url
      },
      email: "",
      password: "",
      eyeIcon: "mdi-eye",
      passwordType: "password"
    };
  },
  computed: {
    errors() {
      const errors = {
        password: [],
        email: []
      };

      if (this.$v.password.$dirty) {
        !this.$v.password.required &&
          errors.password.push("Le mot de passe est requis.");
      }
      if (this.$v.email.$dirty) {
        if (!this.$v.email.email) {
          errors.email.push("L'adresse email doit être valide.");
        }
        if (!this.$v.email.required) {
          errors.email.push("L'adresse email est requis.");
        }
      }
      return errors;
    }
  },
  mounted: function () {
    this.clear();
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.call_login();
      }
    },
    call_login() {
      var credentials = {
        email: this.email,
        password: this.password
      };

      login(this, credentials, this.$route.query.redirect).then((r) => {
        const groups = r.groups.map((g) => g.name);
        const group = groups.length > 0 ? groups[0] : null;
        if (Constant.DEBUG) {
          console.log(r);
          console.log(group);
        }
        switch (group) {
          case Constant.lecteur_bouygue_groupname:
            window.location.replace("/numero_serie");
            break;
          case Constant.admin_infonegoce_groupname:
          case Constant.admin_bouygue_groupname:
            window.location.replace("/overview");
            break;
          default:
            this.$store.commit("error", {
              msg:
                "Vous n'avez pas les autorisations pour accéder à cette application.",
                color: "error"
            });
            break;
        }
      });
    },
    clear() {
      this.$v.$reset();
      this.email = "";
      this.password = "";
      this.checkbox = null;
    },
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
      this.eyeIcon = this.eyeIcon === "mdi-eye" ? "mdi-eye-off" : "mdi-eye";
    }
  }
};
</script>
<style scoped>
.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../assets/bg-cover.jpg);
}

.logo-bte {
  height: "160px";
  margin-left: 0px;
  max-width: 100%;
}

.powered_by {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.lightheader {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 30px;
  margin-left: 10%;
  margin-top: 10%;
}
</style>
