/* eslint-disable space-before-function-paren */
import { HTTP } from "@/utils/http-common";

export default {
  get($, company) {
    //   GET /admin/companies/ (list all companies)
    return new Promise((resolve, reject) => {
      return HTTP.get(`/admin/companies/${company}/`)
        .then(r => {
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Getting company",
            color: "error"
          });
          reject(e);
        });
    });
  },
  list($) {
    //   GET /admin/companies/ (list all companies)
    return new Promise((resolve, reject) => {
      return HTTP.get("/admin/companies/")
        .then(r => {
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Getting companies",
            color: "error"
          });
          reject(e);
        });
    });
  },
  create($, name) {
    const form = {
      name: name
    };
    // POST /admin/company/
    return new Promise((resolve, reject) => {
      return HTTP.post("/admin/companies/", form)
        .then(r => {
          $.$store.commit("success", "Successfully created");
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Creating a company",
            color: "error"
          });
          reject(e);
        });
    });
  },
  update($, form, id) {
    // PUT /admin/company/
    form = {
      name: form.name,
      email: form.email,
      phone_number: form.phone_number,
      website: form.website,
      address: form.address,
      zip_code: form.zip_code,
      city: form.city,
      region: form.region,
      country: form.country
    };
    return new Promise((resolve, reject) => {
      return HTTP.patch(`/admin/companies/${id}/`, form)
        .then(r => {
          $.$store.commit("success", "Company updated !");
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Updating company",
            color: "error"
          });
          reject(e);
        });
    });
  },
  delete($, id) {
    // DELETE /admin/company/:id
    return new Promise((resolve, reject) => {
      return HTTP.delete(`/admin/companies/${id}/`)
        .then(r => {
          $.$store.commit("success", "Company deleted !");
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg: "API call failed : Deleting a company",
            color: "error"
          });
          reject(e);
        });
    });
  },
  update_by_invitation($, form, id, invitationKey) {
    // PUT /admin/users/
    // PUT /admin/company/
    form = {
      username: form.username,
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      password: form.password,
      password2: form.password2,
      invitation_key: invitationKey
    };

    return new Promise((resolve, reject) => {
      return HTTP.patch(`/admin/users/${id}/update_by_invitation/`, form)
        .then(r => {
          $.$store.commit("success", "Utilisateur mis a jour");
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", "L'utilisateur n'a pas pu être mis à jour");
          reject(e);
        });
    });
  },
  User: {
    list_by_company($, company) {
      //   GET /admin/users/?company=himydata (list users of a company)
      return new Promise((resolve, reject) => {
        return HTTP.get("/admin/users/?company=" + company)
          .then(r => {
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Getting company's users",
              color: "error"
            });
            reject(e);
          });
      });
    },
    get($, id) {
      // GET /admin/users/:id (Not needed)
      return new Promise((resolve, reject) => {
        return HTTP.get(`/admin/users/${id}/`)
          .then(r => {
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Getting user",
              color: "error"
            });
            reject(e);
          });
      });
    },
    list($, company) {
      // GET /admin/users/?company=himydata (list of a certain company)
      // GET /admin/users/ (list of all companies)
      return new Promise((resolve, reject) => {
        return HTTP.get("/admin/users/" + (company ? "company=" + company : ""))
          .then(r => {
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Getting users",
              color: "error"
            });
            reject(e);
          });
      });
    },
    create($, form) {
      // POST /admin/users/
      form = {
        company: form.company, // slug
        username: form.username,
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        groups: form.groups
      };
      return new Promise((resolve, reject) => {
        return HTTP.post("/admin/users/", form)
          .then(r => {
            $.$store.commit("success", "Successfully created");
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Creating a new user",
              color: "error"
            });
            reject(e);
          });
      });
    },
    update($, form, id) {
      // PUT /admin/users/
      // PUT /admin/company/
      form = {
        company: form.company, // slug
        username: form.username,
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        groups: form.groups
      };
      return new Promise((resolve, reject) => {
        return HTTP.patch(`/admin/users/${id}/`, form)
          .then(r => {
            $.$store.commit("success", "Successfully updated");
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Deleting a company",
              color: "error"
            });
            reject(e);
          });
      });
    },
    delete($, id) {
      // DELETE /admin/users/:id
      // DELETE /admin/company/:id
      return new Promise((resolve, reject) => {
        return HTTP.delete(`/admin/users/${id}/`)
          .then(r => {
            $.$store.commit("success", "Successfully deleted");
            resolve(r.data);
          })
          .catch(e => {
            $.$store.commit("error", {
              msg: "API call failed : Deleting a company",
              color: "error"
            });
            reject(e);
          });
      });
    }
  }
};
