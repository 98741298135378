<template>
  <v-container>
    <v-row class="mb-3">
      <v-col cols="12" class="pb-0">
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="text-h5 mb-5 mr-4">Facture du mois</span>
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="dialog_date"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  @click="dialog_date = !dialog_date"
                  color="primary darken-1"
                  outlined
                >
                  <v-icon left>mdi-calendar</v-icon>
                  {{ selectedMonth }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                type="month"
                v-model="buffMonth"
                @change="monthChanged"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-dialog v-model="dialog_prix_unitaire">
          <template v-slot:activator="{ on }">
            <span class="subtitle-1"
              >Vous pouvez
              <a v-on="on" class="accent--text">modifier les prix unitaires</a>
              défini par défaut</span
            >
          </template>
          <v-card>
            <v-card-title>Prix unitaire par défaut</v-card-title>
            <v-card-text>
              <hmd-table
                title="Tableau prix unitaire"
                ref="tablePrixUnitaire"
                card
                :dataset="dataset_prix_unitaire"
                :ignored-columns="['primary_key']"
                :blocked-columns="['libelle']"
                iseditable
                @modification="modificationPrixUnitaireEvent"
              />
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="error" outlined @click="closePrixUnitaire"
                >Fermer</v-btn
              >
              <v-btn
                color="primary darken-1"
                outlined
                @click="saveClosePrixUnitaire"
                >Sauvegarder & Fermer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <tableau-facture
      ref="tableFacture"
      :date="{
        year: parseInt(selectedMonth.split('-')[0]),
        month: parseInt(selectedMonth.split('-')[1])
      }"
      @modification="modificationFactureEvent"
    />
  </v-container>
</template>

<script>
import Constant from "@/constant";
import ListFacture from "@/components/bytel/ListFacture";
import TableauFacture from "@/components/bytel/TableauFactureOneLine";
import Table from "@/components/bnc/dashboard/Table";
import moment from "moment";

export default {
  name: "Facturation",
  components: {
    ListFacture,
    TableauFacture,
    "hmd-table": Table
  },
  data: function() {
    return {
      selectedMonth: moment().format("YYYY-MM"),
      dataset_prix_unitaire: Constant.datasetPrixUnitaire.id,
      dialog_prix_unitaire: false,
      modification_prix_unitaire: false,
      modification_facture: false,
      buffMonth: moment().format("YYYY-MM"),
      dialog_date: false
    };
  },
  methods: {
    modificationPrixUnitaireEvent(event) {
      this.modification_prix_unitaire = event;
    },
    modificationFactureEvent(event) {
      this.modification_facture = event;
    },
    saveClosePrixUnitaire() {
      this.$refs.tablePrixUnitaire.save();
      this.dialog_prix_unitaire = false;
    },
    closePrixUnitaire() {
      this.$refs.tablePrixUnitaire.reset();
      this.dialog_prix_unitaire = false;
    },
    monthChanged() {
      //Verify that the user has not modified the Facture without validating before changing to another facture
      if (this.modification_facture) {
        this.$store.commit("confirm", {
          message:
            "Vous avez modifié la facture de ce mois-ci. Souhaitez-vous sauvegarder vos modifications avant de changer de mois ?",
          title: "Modification en attente",
          color: "warning",
          onCancel: () => {
            this.selectedMonth = this.buffMonth;
          },
          onConfirm: () => {
            this.$refs.tableFacture.save().then(() => {
              this.selectedMonth = this.buffMonth;
            });
          }
        });
      } else {
        this.selectedMonth = this.buffMonth;
      }
      this.dialog_date = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    const messagePrixUnitaire =
      "Vous êtes en pleine modification des prix unitaire, si vous quittez maintenant vos modifications ne seront pas sauvegardés. Souhaitez-vous sauvegarder avant de quitter la page ?";
    const messageFacture =
      "Vous êtes entrain de modifier la facture, si vous quittez maintenant vos modifications ne seront pas sauvegardées. Souhaitez-vous sauvegarder avant de quitter la page ?";

    if (this.modification_prix_unitaire || this.modification_facture) {
      if (this.modification_prix_unitaire) {
        this.$store.commit("confirm", {
          message: messagePrixUnitaire,
          title: "Modification en attente",
          color: "warning",
          onCancel: () => {
            next();
          },
          onConfirm: () => {
            this.$refs.tablePrixUnitaire.save().then(() => {
              this.modification_prix_unitaire = false;
              if (!this.modification_facture) {
                next();
              }
            });
          }
        });
      }
      if (this.modification_facture) {
        this.$store.commit("confirm", {
          message: messageFacture,
          title: "Modification en attente",
          color: "warning",
          onCancel: () => {
            next();
          },
          onConfirm: () => {
            this.$refs.tableFacture.save().then(() => {
              this.modification_facture = false;
              if (!this.modification_prix_unitaire) {
                next();
              }
            });
          }
        });
      }
    } else {
      next();
    }
  }
};
</script>
