<template>
  <v-dialog
    v-model="dialog"
    :max-width="!!options.width?options.width:290"
    :style="{ zIndex: 200 }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
      >
        <v-toolbar-title class="white--text">
          {{ options.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!options.message"
        class="pa-4"
      >
        {{ options.message }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          text
          @click.native="cancel"
        >
          Annuler
        </v-btn>
        <v-btn
          depressed
          @click.native="agree"
        >
          Continuer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'primary',
        width: 290,
        title: 'Hellew',
        message: 'haaa',
      },
    }),
    watch: {
      '$store.state.app.confirmLine' (vn, vo) {
        const conf = vn[vn.length - 1]
        this.open(conf)
      },
    },
    methods: {
      open (options) {
        this.options = options
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree () {
        this.resolve(true)
        if (this.options.onConfirm) { this.options.onConfirm() }
        this.dialog = false
      },
      cancel () {
        this.resolve(false)
        if (this.options.onCancel) { this.options.onCancel() }
        this.dialog = false
      },
    },
  }
</script>
