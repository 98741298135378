<template>
  <v-container fluid>
    <v-row>
      <v-col class="position-relative">
        <a :href="app.url" target="_blank"
          ><img
            src="../../assets/logo-mfm.png"
            height="70px"
        /></a>
        <h1 class="thin mt-5">
          Créez votre compte
        </h1>
        <h3 class="thin mb-4">
          Vous avez déja un compte ? <a href="/signin">Se connecter</a>
        </h3>
        <h3 class="thin my3">Invité par {{ invited_by.name }}</h3>
        <form>
          <v-row>
            <!-- company name field END -->
            <!-- email field START -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Adresse email"
                :error-messages="errors.email"
                disabled
                required
                v-model="$v.signupForm.email.$model"
                @input="updateForm"
              />
            </v-col>
            <!-- email field END -->
            <!-- first name field START -->
            <v-col class="pr-2 py-0" cols="6">
              <v-text-field
                v-model="$v.signupForm.first_name.$model"
                :error-messages="errors.first_name"
                label="Prénom"
                class="input-group--focused"
                required
                @input="updateForm"
              />
            </v-col>
            <!-- first name field END -->
            <!-- last name field START -->
            <v-col class="pl-2 py-0" cols="6">
              <v-text-field
                v-model="$v.signupForm.last_name.$model"
                :error-messages="errors.last_name"
                label="Nom"
                required
                @input="updateForm"
              />
            </v-col>
            <!-- last name field END -->
            <!-- password field START -->
            <v-col cols="12" class="py-0">
              <password-field
                v-model="$v.signupForm.password.$model"
                placeholder="Veuillez entrer un mot de passe"
                @input="updateForm"
                complexity-requirements
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <password-field
                v-model="$v.signupForm.password2.$model"
                placeholder="Veuillez répeter le mot de passe"
                @input="updateForm"
                complexity-requirements
                :same-as="$v.signupForm.password.$model"
              />
            </v-col>
            <!-- password field END -->
            <!-- sign up button START -->
            <v-col cols="12" class="text-center">
              <v-container v-show="!isValidated">
                <v-btn
                  large
                  :loading="loading"
                  :disabled="canSubmit"
                  color="primary"
                  block
                  @click="submit"
                >
                  S'inscrire
                </v-btn>
              </v-container>
              <v-container v-show="isValidated" class="slide-x-transition">
                <p>Vous allez être rediriger au bout de 5 secondes.</p>
                <p>
                  <a @click="$router.push('/signin')" class="accent--text"
                    >Si vous n'êtes pas automatiquement rediriger veuillez
                    cliquer ici.</a
                  >
                </p>
              </v-container>
            </v-col>
            <!-- sign up button END -->
          </v-row>
        </form>
        <!-- form END -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from "@/services/CompanyService";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { isComplexPassword } from "@/utils/regex_validator";
import PasswordField from "@/components/base/PasswordField";

const Config = require("@/utils/theme-config");
export default {
  components: { PasswordField },
  mixins: [validationMixin],
  validations: {
    signupForm: {
      email: { required },
      username: { required },
      first_name: {},
      last_name: {},
      password: {
        required,
        isComplexPassword
      },
      password2: {
        required,
        sameAsP1: sameAs(function() {
          return this.signupForm.password;
        }),
        isComplexPassword
      }
    }
  },
  data() {
    return {
      app: {
        name: Config.app.name,
        url: Config.app.url
      },
      signupForm: {
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: ""
      },
      loading: false,
      isValidated: false
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          email: "",
          username: "",
          first_name: "",
          last_name: "",
          password: "",
          password2: ""
        };
      }
    },
    invitation_key: {
      type: String,
      default: ""
    },
    invited_by: {
      type: Object,
      default: () => {
        return {
          name: "",
          company: ""
        };
      }
    },
    user_pk: {
      type: Number,
      default: -1
    }
  },
  computed: {
    errors() {
      const errors = {
        email: [],
        username: [],
        first_name: [],
        last_name: [],
        password: [],
        password2: []
      };

      this.$v.signupForm.email.$dirty &&
        !this.$v.signupForm.email.required &&
        errors.email.push("L'email est requis");

      this.$v.signupForm.username.$dirty &&
        !this.$v.signupForm.username.required &&
        errors.username.push("Le nom d'utilisateur est requis");

      this.$v.signupForm.password.$dirty &&
        !this.$v.signupForm.password.required &&
        errors.password.push("Le mot de passe est requis");

      this.$v.signupForm.password2.$dirty &&
        !this.$v.signupForm.password2.required &&
        errors.password2.push("Le mot de passe est requis");

      this.$v.signupForm.password.$dirty &&
        !this.$v.signupForm.password.isComplexPassword &&
        errors.password.push(
          "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
        );

      this.$v.signupForm.password2.$dirty &&
        !this.$v.signupForm.password2.isComplexPassword &&
        errors.password2.push(
          "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
        );

      this.$v.signupForm.password2.$dirty &&
        !this.$v.signupForm.password2.sameAsP1 &&
        errors.password2.push("Les mots de passe ne sont pas identiques");

      return errors;
    },
    canSubmit() {
      return this.$v.signupForm.$invalid || this.loading;
    }
  },
  methods: {
    submit() {
      // Update de l'user
      if (!this.$v.signupForm.$invalid && this.user_pk >= 0) {
        this.loading = true;
        // Update user if it work accept the invitation
        CompanyService.update_by_invitation(
          this,
          this.signupForm,
          this.user_pk,
          this.invitation_key
        )
          .then(result => {
            this.$store.commit(
              "success",
              "Votre compte a été créer, vous allez être rediriger sur la page de connexion."
            );
            setTimeout(() => {
              window.location.replace("/signin");
            }, 1500);
            this.isValidated = true;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
        // console.log(this.signupForm);
      }
    },
    updateForm() {
      this.$emit("input", this.signupForm);
    }
  },
  mounted() {
    console.log(CompanyService);
    setTimeout(() => {
      this.signupForm.email = this.value.email;
      this.signupForm.username = this.value.email;
      this.signupForm.first_name = this.value.first_name;
      this.signupForm.last_name = this.value.last_name;
      this.signupForm.password = this.value.password;
      this.signupForm.password2 = this.value.password2;
    }, 500);
  }
};
</script>

<style>
.bg-cover {
  background-color: #0e264a;
  background-position: 50% 50%;
  background-image: url(../../assets/signin_cover.png);
}

.pa-7 {
  padding: 70px;
}

.thin {
  font-weight: 300;
}

@media only screen and (max-width: 450px) {
  .pa-7 {
    padding: 35px;
  }
}
</style>
